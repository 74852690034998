import { NavLink, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

export const Header = ({ linksArray }) => {
  const location = useLocation();
  const [scrolled, setScrolled] = useState();
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    const listener = function () {
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      setScrolled(scrollTop > 10);

      if (scrollTop > 10) setExpanded(false);
    };
    window.addEventListener("scroll", listener, { passive: true });

    return () => {
      window.removeEventListener("scroll", listener, { passive: true });
    };
  }, []);

  const toggleMenu = () => {
    setExpanded((current) => !current);
  };

  const [touchStartPoint, setTouchStartPoint] = useState(null);
  const touchStart = (ev) => {
    setTouchStartPoint(ev.touches[0].pageY);
  };

  const touchMove = (ev) => {
    if (
      document.scrollingElement.scrollTop === 0 &&
      ev.touches[0].pageY > touchStartPoint
    ) {
      setExpanded(true);
    } else if (ev.touches[0].pageY < touchStartPoint) {
      setExpanded(false);
    }
  };

  return (
    <nav
      className={
        "Navbar text-lg" +
        (!!scrolled ? " Navbar--scrolled " : "") +
        (!!expanded ? " Navbar--expanded " : "")
      }
      aria-label="Main"
      onTouchStart={touchStart}
      onTouchMove={touchMove}
    >
      <ul>
        <li className="site-name text-4xl">
          <NavLink
            to="/"
            className="Navbar__item site-name hover:text-cyan-700"
            title="Home page"
          >
            <span>Ricky</span> “Masala” Nilsfred
          </NavLink>
          <button
            className="Navbar__opener"
            title="Open menu"
            onClick={toggleMenu}
          >
            Open menu
          </button>
        </li>

        {linksArray.map((link, i) => (
          <li
            className={i < linksArray.length / 2 ? "order-1" : "order-10"}
            key={`headernav_${i}`}
          >
            <NavLink
              to={link.path}
              className={`Navbar__item text-${link.color}-900`}
            >
              {link.label}
            </NavLink>
          </li>
        ))}
      </ul>
    </nav>
  );
};
