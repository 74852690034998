import { useState, useEffect, useCallback } from "react";
import { ReactComponent as RingNavInner } from "../svg/tinker.svg";

/**
 * The drag rotation HEAVILY inspired by / plagiative(?) of
 * https://codepen.io/jsguy/pen/NWGapLB
 * Adapted for React; could probably be tidied somewhat
 */
export const RingNav = ({ background }) => {
  const [active, setActive] = useState(false),
    [angle, setAngle] = useState(0),
    [rotation, setRotation] = useState(0),
    [startAngle, setStartAngle] = useState(0),
    [centre, setCentre] = useState({ x: 0, y: 0 });

  const R2D = 180 / Math.PI;

  const start = (ev) => {
    ev.preventDefault();
    let bb = ev.target.closest(".Ring-nav__ring").getBoundingClientRect(), // mildly buggy in ios safari?
      t = bb.top,
      l = bb.left,
      h = bb.height,
      w = bb.width,
      x,
      y;

    let c = {
      x: l + w / 2,
      y: t + h / 2
    };
    setCentre(c);

    if (ev.type === "mousedown") {
      x = ev.clientX - c.x;
      y = ev.clientY - c.y;
    } else if (ev.type === "touchstart") {
      x = ev.touches[0].clientX - c.x; // NB should be able to assume at least one touch in a touchstart :)
      y = ev.touches[0].clientY - c.y;
    }

    let tspan = ev.target.closest("tspan");
    let tsi = [...tspan.parentNode?.children].indexOf(tspan);

    setStartAngle(R2D * Math.atan2(y, x));
    setActive(tsi > -1 ? tsi + 1 : true); //
  };

  const stop = useCallback(
    (ev) => {
      ev.preventDefault();
      setAngle((prevAngle) => prevAngle + rotation);
      setRotation(0); // TODO: any race conditions with the above?
      setActive(false);
    },
    [rotation]
  );

  const rotate = useCallback(
    (ev) => {
      if (!active) return;

      ev.preventDefault();

      let x, y;
      if (ev.type === "mousemove") {
        x = ev.clientX - centre.x;
        y = ev.clientY - centre.y;
      } else if (ev.type === "touchmove") {
        x = ev.touches[0].clientX - centre.x;
        y = ev.touches[0].clientY - centre.y;
      }

      let d = R2D * Math.atan2(y, x);
      setRotation(d - startAngle);
    },
    [active, startAngle, centre]
  );

  useEffect(() => {
    document.addEventListener("mousemove", rotate);
    document.addEventListener("touchmove", rotate);
    document.addEventListener("mouseup", stop);
    document.addEventListener("touchend", stop);

    return () => {
      document.removeEventListener("mousemove", rotate);
      document.removeEventListener("touchmove", rotate);
      document.removeEventListener("mouseup", stop);
      document.removeEventListener("touchend", stop);
    };
  }, [rotate, stop]);

  return (
    <div
      className={
        (background === true ? "Ring-nav--background" : "") + " Ring-nav"
      }
    >
      <div
        className="w-full h-full"
        style={{
          rotate: `${angle + rotation}deg`
          // Use rotate prop instead of transform: rotate() to avoid conflict with animation
        }}
      >
        <svg
          viewBox="0 0 100 100"
          width="100"
          height="100"
          xmlns="http://www.w3.org/2000/svg"
          className={"Ring-nav__ring " + (!!active ? "manually-rotating" : "")}
        >
          <defs>
            <path
              id="circle"
              d="
                M 50, 50
                m -43, 0
                a 43,43 0 1,1 86,0
                a 43,43 0 1,1 -86,0
            "
            />
            <linearGradient
              id="ringnav_rainbow"
              x1="0"
              x2="0"
              y1="0"
              y2="100%"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FF5B99" offset="0%" />
              <stop stopColor="#FF5447" offset="20%" />
              <stop stopColor="#FF7B21" offset="40%" />
              <stop stopColor="#EAFC37" offset="60%" />
              <stop stopColor="#4FCB6B" offset="80%" />
              <stop stopColor="#51F7FE" offset="100%" />
            </linearGradient>
          </defs>
          <text>
            <textPath
              href="#circle"
              style={{
                fontFamily: "MigraineSans",
                fontSize: "9px",
                userSelect: "none"
              }}
              onMouseDown={start}
              onTouchStart={start}
            >
              <tspan className={active === 1 ? "active" : ""}>
                pixel pusher
              </tspan>{" "}
              <tspan className={active === 2 ? "active" : ""}>
                tag mangler
              </tspan>{" "}
              <tspan className={active === 3 ? "active" : ""}>
                music maker
              </tspan>{" "}
              <tspan className={active === 4 ? "active" : ""}>
                hair splitter
              </tspan>{" "}
              <tspan className={active === 5 ? "active" : ""}>storysmith</tspan>
            </textPath>
          </text>
        </svg>
      </div>
      <RingNavInner />
    </div>
  );
};
