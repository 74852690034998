import { NavLink } from "react-router-dom";
import Icon from "./Icon";
import { ReactComponent as CC } from "../svg/cc.svg";
import { ReactComponent as By } from "../svg/by.svg";
import React from "react";

export const Footer = ({ mainNavLinks }) => {
  const [scrolled, setScrolled] = React.useState(false);

  // Scroll listener that sets scrolled=true if the navbar considers itself scrolled
  React.useEffect(() => {
    const listener = function () {
      setScrolled(
        document.querySelector(".Navbar").classList.contains("Navbar--scrolled")
      );
    };
    window.addEventListener("scroll", listener, { passive: true });

    return () => {
      window.removeEventListener("scroll", listener, { passive: true });
    };
  }, []);

  return (
    <>
      <footer>
        <ul className="text-right opacity-50 hover:opacity-100 text-sm float-right table transition">
          <li>
            <a
              href="https://creativecommons.org/licenses/by/4.0/"
              title="This work is licensed under a Creative Commons Attribution 4.0 International License."
              className="border-none hover:border-none"
            >
              <CC className="inline-icon" />
              <By className="inline-icon" />
            </a>{" "}
            Ricky "Masala" Nilsfred
          </li>
          <li>
            <NavLink to="/">Home</NavLink>
            {mainNavLinks.map((link, i) => (
              <React.Fragment key={`footernav_${i}`}>
                {" "}
                • <NavLink to={link.path}>{link.label}</NavLink>
              </React.Fragment>
            ))}
          </li>
          <li>
            <a href="mailto:ricky@nilsfred.net">ricky@nilsfred.net</a>
          </li>
          <li>
            <a href="/CV.pdf" download="CV_RickyMasalaNilsfred.pdf">
              <Icon type="doc" className="inline-icon" /> Resumé (pdf)
            </a>
          </li>
        </ul>
      </footer>
      <a
        className={`w-32 h-32 fixed -bottom-20 left-0 right-0 m-auto z-50 pt-6 
          bg-slate-500 rounded-full text-center text-slate-50 font-bold uppercase 
          opacity-40 hover:opacity-60 focus:opacity-60 transition-opacity backdrop-blur-lg 
          ${!scrolled ? "opacity-0" : ""}`}
        href="#root"
      >
        To top
      </a>
    </>
  );
};
