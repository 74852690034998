import { ReactComponent as Dollar } from "../svg/i-dollar.svg";
import { ReactComponent as StrikeDollar } from "../svg/i-strikedollar.svg";
import { ReactComponent as Trophy } from "../svg/i-trophy.svg";
import { ReactComponent as Desktop } from "../svg/i-desktop.svg";
import { ReactComponent as Code } from "../svg/i-code.svg";
import { ReactComponent as Megaphone } from "../svg/i-megaphone.svg";
import { ReactComponent as Book } from "../svg/i-book.svg";
import { ReactComponent as Mobile } from "../svg/i-mobile.svg";
import { ReactComponent as Download } from "../svg/i-dl.svg";
import { ReactComponent as Out } from "../svg/i-out.svg";
import { ReactComponent as Doc } from "../svg/i-doc.svg";
//import { ReactComponent as Brush } from "../svg/i-brush.svg";
//import { ReactComponent as Key } from "../svg/i-key.svg";

const Icon = (props) => {
  switch (props.type.toLowerCase()) {
    case "dollar":
      return <Dollar {...props} />;
    case "strike-dollar":
      return <StrikeDollar {...props} />;
    case "award":
      return <Trophy {...props} />;
    case "desktop":
      return <Desktop {...props} />;
    case "code":
      return <Code {...props} />;
    case "branding":
      return <Megaphone {...props} />;
    case "book":
      return <Book {...props} />;
    case "mobile":
      return <Mobile {...props} />;
    case "download":
      return <Download {...props} />;
    case "out":
      return <Out {...props} />;
    case "doc":
    default:
      return <Doc {...props} />;
  }
};

export default Icon;
