import React, { lazy, Suspense } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

import { Header } from "./components/Header";
import { Footer } from "./components/Footer";
import { RingNav } from "./components/RingNav";
import { Home } from "./components/pages/Home";
import { Skeleton } from "./components/Skeleton";

const About = lazy(() => import("./components/pages/About"));
const Skills = lazy(() => import("./components/pages/Skills"));
const Portfolio = lazy(() => import("./components/pages/Portfolio"));
const Profile = lazy(() => import("./components/pages/Profile"));

function App() {
  let location = useLocation();

  const mainNavLinks = [
    {
      path: "/profile",
      label: "Profile",
      color: "green"
    },
    {
      path: "/about",
      label: "on Masala",
      color: "red"
    },
    {
      path: "/skills",
      label: "Skill graph",
      color: "purple"
    },
    {
      path: "/portfolio",
      label: "Portfolio",
      color: "sky"
    }
  ];

  return (
    <div className={"App " + (location.pathname === "/" ? " front" : "")}>
      <Header linksArray={mainNavLinks} />
      <RingNav background={location.pathname !== "/" ? true : false} />
      <Suspense fallback={<Skeleton />}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="skills" element={<Skills />} />
          <Route path="portfolio" element={<Portfolio />}>
            <Route path=":project" element={<Portfolio />} />
          </Route>
          <Route path="profile" element={<Profile />} />
        </Routes>
      </Suspense>
      {location.pathname !== "/" && <Footer mainNavLinks={mainNavLinks} />}
    </div>
  );
}

export default App;
